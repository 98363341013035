body {
  background-color: #ffffff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.containerMain {
  padding-top: 0;
  background-color: #fff;
}

#menu {
  background-color: #185e81;
  /* opacity: 0.8; */
  padding: 18px 0 21px 0;
  position: relative;
  z-index: 10;
  border-top: #feda00 solid 6px;
  text-align: center;
  /* width: 100%; */
  align-items: flex-start;
}

.navMenuItem {
  background-color: #185e81;
  color: #D1E3FB;
  z-index: 10;
}

.dropdown-menu {
  font-size: 16px;
  background-color: #0C2E3F;
  color: #D1E3FB;
  opacity: 0.8;
}

.dropdown-menu[data-bs-popper] {
  top: 70%;
}

.navMenuItem:hover {
  background-color: #0C2E3F;
  color: #D1E3FB;
  opacity: 0.8;
  position: relative;
  z-index: 999999999;
  text-align: center;
  font-size: 18px;
}

.established {
  font-size: 16px;
  color: #D1E3FB;
}

.basic-nav-dropdown {
  padding: 0px 25px 0px 25px;
  text-decoration: none;
  letter-spacing: 1px;
}

.navbar-expand .navbar-nav .nav-link {
  color: #D1E3FB;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Arial Regular, Arial, sans-serif", Arial Regular, Arial, sans-serif;
}

.slider {
  box-sizing: border-box;
  margin-bottom: -8px;
}

.goLine {
  background-color: #185e81;
  color: #feda00;
  opacity: 0.8;
  padding: 15px 15% 10px 15%;
  text-transform: uppercase;
  font-size: 16px;
  /* max-width: 100%; */
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.mainBlock {
  background: url(./images/fon-01.png) no-repeat #feda00 right top;
}


.mainHeader {
  font-size: 28px;
  font-weight: 700;
  font-family: Arial bold, Arial, sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: #e82277;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 9px;
  margin: 20px 0 10px;
  border-bottom: 1px solid #eeeeee;
}

.mainBlockText {
  padding: 3% 20% 5% 10%;
}

#navbarScroll {
  position: absolute;
  top: 0px;
  right: 0px;
}

/* .supportUsButton {
  background-image: linear-gradient(to right, #0057B8 , #FFD700);
  border-color: transparent;
} */

.supportUsButton {
  background-image: linear-gradient(to bottom, #0057B8 , #FFD700);
  border-color: transparent;
}

.navigationButton {
  background-color: #81b0e0;
    color: #fff;
    padding: 5px 5px;
    margin-top: 10px;
    box-shadow: 2px 5px 10px 0px rgb(0 0 0 / 75%);
    margin-right: 1%;
    font-size: 0.97em;
    text-align: left;
    width: 13%;
    text-transform: uppercase;
}

.footerText {
  font-size: 10px;
  text-align: left;
}

.navbar-expand {
  padding-top: 0;
  padding-bottom: 0;
}

.mainBlockNavigationButtons {
  width: 20%;
  height: 20%;
}

.mainBlockNavigationText {
  display: inline-block;
  justify-content: end;
  text-align: center;
}

.footerTop {
  border-top: solid #feda00 3px;
  margin-top: 20px;
  padding-bottom: 0;
}

.footerSeparator {
  border-right: solid #feda00 2px;
}

.footerLogo {
  max-height: 30px;
}

.footerFollowUs {
  margin-top: 5px;
}

.mainLayoutBlock {
  margin-top: 20px;
}


#ticker {
  max-width: 75%;
}

#ticker li {
  color: #4e4e4e;
  background: transparent;
  overflow: hidden;
  padding: 10px;
  line-height: 30px;
  font-size: 16px;
  text-align: left;
  border-bottom: 1px dotted #3c729e;
}

#ticker li:hover {
  background: rgb(236, 235, 235);
}

.SiderbarBackground {
  background: url(./images/fon-01.png) no-repeat #ffffff right bottom;
  margin-right: 12px;
}

.cardsBackground {
  background: url(./images/fon-01.png) no-repeat #ffffff right bottom;
  border: inset 1px;
  margin-top: 45px;
  font-family: 'Calibri, Candara, Segoe, “Segoe UI”, Optima, Arial, sans-serif';
  width: 15rem;
  font-size: 14px;
  margin-right: 25px;
  background-size: 30%;
}

.card-body {
  padding-top: 0;
}

.card-img-top {
  padding-bottom: 0;
}

.calibriFontFamily {
  font-family: 'Calibri, Candara, Segoe, “Segoe UI”, Optima, Arial, sans-serif';
}

.sideNavigationLink {
  text-decoration: none;
  color: black;
  font-size: large;
  padding: 5px;
}

.sideNavigationLink:hover {
  background-color: lightgray;
}

.languageButton {
  background-color: none; 
  border: none;
  background: none; 
  margin-right: 5px; 
  margin-left: 5px;
}

.react-multi-carousel-item {
    top: 50%;
    bottom: 50%;
    align-items: center;
    display: flex;
}

@media only screen and (max-width: 769px) {
  .directorCard {
    align-items: flex-start!important;
    justify-content: flex-start!important;
  }
  
  .footerText {
    font-size: 8px;
    padding: 0px;
  }

  .footerSeparator {
    border-right: none;
  }
}

@media only screen and (max-width: 986px) {
  .basic-nav-dropdown {
    padding: 0px 25px 0px 15px;
    text-decoration: none;
    letter-spacing: 1px;
  }
  
  .navbar-expand .navbar-nav .nav-link {
    color: #D1E3FB;
    text-transform: uppercase;
    font-size: 12px;
    font-family: "Arial Regular, Arial, sans-serif", Arial Regular, Arial, sans-serif;
  }

  .footerLogo{
    max-height: 20px;
  }

  #root {
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 1211px) {
  .logosForNavigation {
    display: none;
  }

  .mainBlockNavigationText {
    font-size: 14px;
  }

  .established {
    font-size: 12px;
  }

  .languageButton img {
    width: 16px;
  }

  .supportUsButton {
    font-size: 12px;
    margin-top: 5px;
    margin-left: 2px;
  }

  .basic-nav-dropdown {
    padding: 0px 5px 0px 5px;
  }

  #menu a img {
    width: 50px;
    height: 50px;
  }

  .navbar-expand .navbar-nav .nav-link {
    font-size: 12px;
  }

  .react-multi-carousel-dot {
    display: none;
  }
}
